export default function SwipeControl(container, binding, config = null) {
  const transition = "transform 0.5s ease-out 0s";
  const reference = {x: 0, y: 0};

  let memory = [];

  let index = binding.getIndex();
  let newIndex;
  let width = binding.getWidth();

  const elements = binding.getElements();

  if (elements.length <= 1) return;

  if (config != null) {
    if (config.updateWidth !== undefined && config.updateWidth) {
      window.addEventListener('resize', function() {
        width = binding.getWidth();
      });
    }
  }

  let job;
  let ticking = false;
  let animating = false;

  function start(event) {
    if (binding.onBegin != null)
      binding.onBegin();

    if (job != null) {
      clearInterval(job);
      aborted();
    }

    reference.x = event.touches[0].pageX;
    reference.y = event.touches[0].pageY;

    animating = true;
  }

  function move(event) {
    const x = event.touches[0].pageX;
    const y = event.touches[0].pageY;

    let dx = x - reference.x;
    let dy = y - reference.y;

    if ((index === 0 && dx > 0) || (index === elements.length -1 && dx < 0)) {
      dx = 0;
    } else if (dx > width) {
      dx = width;
    } else if (dx < -width) {
      dx = -width;
    }

    if (dx > dy) {
      event.preventDefault();
    }

    if (!ticking) {
      ticking = true;
      window.requestAnimationFrame(function() {
        if (animating)
          container.style.transform = "translateX(" + (-1 * index * width + dx) + "px)";

        ticking = false;
      });
    }

    memory.push({x: x, dx: dx, y: y, dy: dy});
  }

  function end(event) {
    animating = false;

    if (memory.length < 1)
      return;

    const swipe = -1 * memory[memory.length - 1].dx / width;
    let di = 0;

    if (swipe < -0.2 && index > 0)
      di--;

    if (swipe > 0.2 && index < elements.length - 1)
      di++;

    container.style.transition = transition;
    container.style.transform = "translateX(" + (-1 * (index + di) * width) + "px)";

    memory = [];
    newIndex = index + di;

    job = setTimeout(finished, 550);
  }

  function after() {
    container.style.transition = "none";

    binding.setIndex(newIndex);
    newIndex = null;
  }

  function aborted() {
    after();

    if (binding.onAbort != null) {
      binding.onAbort();
    }
  }

  function finished() {
    job = null;

    after();

    if (binding.onFinished != null) {
      binding.onFinished();
    }
  }

  container.addEventListener('touchstart', start, { passive: false });
  container.addEventListener('touchmove', move, { passive: false });
  container.addEventListener('touchend', end, { passive: false });
  container.addEventListener('touchcancel', end, { passive: false });

  return {
    updateIndex: function(i = null) {
      index = i != null ? i : binding.getIndex();
    },
    getIndex: function() {
      return index;
    }
  };
}
