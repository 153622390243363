import '@css/pages/index.pcss';
import SwipeControl from '@lib/swipe-control.js';

/******** SLIDER *******/

/**
 * Animiert die Slides im Header
 */
(function() {
  const slider = document.querySelector('.page-header--type-slider');
  const slides = slider.querySelectorAll('.page-header__slide');
  const container = slider.querySelector('.page-header__slides');

  const buttons = {
    previous: slider.querySelector('.page-header__button-previous'),
    toggle: slider.querySelector('.page-header__button-toggle'),
    next: slider.querySelector('.page-header__button-next')
  }

  let shownSlide = 0;

  let job = null;
  let nextJob = null;
  let swipe = null;

  let autoResumeEnabled = false;

  let swipeHintSuccess = false; // Für Hinweis

  buttons.previous.addEventListener('click', function() {
    show(shownSlide - 1);
    postpone();
  });

  buttons.toggle.addEventListener('click', function() {
    if (isRunning()) {
      stop();
    } else {
      resume();
    }
  });

  buttons.next.addEventListener('click', function() {
    show(shownSlide + 1);
    postpone();
  });

  if (slides.length > 1) {
    swipe = new SwipeControl(container, {
      getIndex: function() {
        return shownSlide;
      },
      setIndex: function(index) {
        show(index);
        swipeHintSuccess = true;
      },
      getWidth: function() {
        return document.body.clientWidth;
      },
      getElements: function() {
        return slider.querySelectorAll('.page-header__slide');
      },
      onBegin: function() {
        slider.classList.remove('page-header--mode-auto');

        if (isRunning()) {
          autoResumeEnabled = true;
          stop();
        } else {
          autoResumeEnabled = false;
        }

        slides.forEach(function(slide) {
          slide.style.transform = "none";
        });
      },
      onFinished: function() {
        slider.classList.add('page-header--mode-auto');

        if (autoResumeEnabled) {
          job = setTimeout(resume, 7000);
          updateState();
        }
      }
    }, {
      updateWidth: true
    });
  }

  function next() {
    if (slides.length <= 1) return;

    for (var i = 0; i < slides.length; i++) {
      if (shownSlide === i) {
        slides[i].classList.add('page-header__slide--before-hide');
      }
    }

    nextJob = setTimeout(function() {
      show(shownSlide + 1);
    }, 1500);

    updateState();
  }

  function show(index) {
    if (slides.length === 0) return;

    if (index < 0) {
      index = slides.length - 1;
    } else if (index >= slides.length) {
      index = 0;
    }

    container.style.transform = "translateX(" + (-index * 100) + "vw)";

    for (var i = 0; i < slides.length; i++) {
      slides[i].style.transform = "translateX(" + ((index - i) * 100) + "vw)";

      if (index === i) {
        slides[i].classList.add('page-header__slide--shown');
      } else {
        slides[i].classList.remove('page-header__slide--shown');
        slides[i].classList.remove('page-header__slide--before-hide');
      }
    }

    shownSlide = index;

    if (swipe != null) {
      swipe.updateIndex(index);
    }
  }

  function start() {
    if (slides.length > 0) {
      slider.classList.add('page-header--mode-auto');

      show(0);
      slides[0].classList.remove('page-header__slide--init');

      if (slides.length > 1) {
        job = setTimeout(function() {
          next();
          job = setInterval(next, 5000);
        }, 3500);
      }
    }

    updateState();
  }

  function stop() {
    clearInterval(job);
    job = null;

    clearTimeout(nextJob);
    nextJob = null;

    updateState();
  }

  function postpone() {
    if (!isRunning()) return;

    stop();

    job = setInterval(resume, 6000);

    updateState();
  }

  function resume() {
    if (job != null) stop();

    slider.classList.add('page-header--mode-auto');

    if (slides.length > 1) {
      show(shownSlide + 1);

      job = setTimeout(function() {
        next();
        job = setInterval(next, 5000);
      }, 3500);
    }

    updateState();
  }

  function isRunning() {
    return job != null || nextJob != null;
  }

  function updateState() {
    const toggleIcon = buttons.toggle.querySelector('.page-header__button__icon');
    if (isRunning()) {
      toggleIcon.classList.replace('fa-play', 'fa-pause');
    } else {
      toggleIcon.classList.replace('fa-pause', 'fa-play');
    }
  }

  slider.querySelectorAll('.page-header__slide--auto-pause').forEach(function(elem) {
    elem.addEventListener('mouseenter', function() {
      if (isRunning()) {
        autoResumeEnabled = true;
        stop();
      } else {
        autoResumeEnabled = false;
      }
    });

    elem.addEventListener('mouseleave', function() {
      if (autoResumeEnabled) {
        nextJob = setTimeout(resume, 1000);
        updateState();
      }
    });
  });

  nextJob = setTimeout(start, 500);

  window.slider = {
    start: start,
    resume: resume,
    stop: stop,
    show: show,
    swipe: swipe,
    getIndex: function() {
      return shownSlide;
    },
    getSwipeHintSuccess: function() {
      return swipeHintSuccess;
    }
  };
})();
